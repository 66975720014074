<template>
  <div>
    <v-container fluid class="homeVideo__style">
      <v-container class="mx-auto px-lg-0 px-5">
        <v-row class="responsive__style">
          <v-col cols="12" lg="7">
            <p class="font-weight-bold text-h4 white--text mb-1">{{ $t('about.videoText') }}
              <!-- <u class="text-decoration-underline"style="color:#ff7350">Qeducato </u> &  -->
            </p>
            <p class="font-weight-bold text-h4 mb-1" style="color:#ff7350;">{{ $t('about.videoText1') }}</p>
            <p class="font-weight-bold text-h4 white--text">{{ $t('about.videoText2') }}</p>
            <p class="white--text text-body-1">{{ $t('about.videoText3') }}</p>
          </v-col>
          <v-col cols="12" lg="5" class="d-flex justify-center align-center mt-lg-0 mt-10">
            <div class="video__circle"></div>
            <v-dialog v-model="dialog" max-width="800px" @input="closeVideo()">
              <template v-slot:activator="{ on, attrs }">
                <lord-icon @click="openVideo()" v-bind="attrs" v-on="on" class="playPause" trigger="none"
                  src="https://cdn.lordicon.com/dpohzien.json" colors="primary:#ffffff" style="width:55px;height:55px;">
                </lord-icon>
              </template>
              <div>
                <div class="d-flex justify-end pa-3"><v-btn icon>
                  <v-icon style="cursor: pointer;" color="white" @click="closeVideo()">mdi-close</v-icon>
                </v-btn>
              </div>
                <iframe ref="videoIframe" width="100%" height="450" frameborder="0" allowfullscreen
                  :src="videoSrc">
                </iframe>
              </div>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HomeVideo',
  data() {
    return {
      dialog: false,
      videoSrc: 'https://www.youtube.com/embed/6bDlMw5VhXM'
    }
  },
  methods: {
    openVideo(){
      this.dialog = !this.dialog;
      this.videoSrc = 'https://www.youtube.com/embed/6bDlMw5VhXM';
    },
    closeVideo() {
      this.dialog = !this.dialog;
      this.videoSrc = ''
    }
  },
  watch:{
    openVideo(){
      this.$forceUpdate();
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.homeVideo__style {
  width: 100%;
  margin: 60px 0;
  padding: 160px 0;
  background-image: url('../../assets/image/video.png');
  background-size: cover;
  background-position: center;
}

.video__circle {
  width: 85px;
  height: 85px;
  position: relative;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scale 4s infinite;
  cursor: pointer;
}

.playPause {
  position: absolute;
  cursor: pointer;
}

@keyframes scale {
  0% {
    transform: scale(1);
    /*Уменьшение элемента*/
    opacity: 0.5;
    /*Уровень прозрачности*/
    border: 5px solid rgba(255, 255, 255, 0.143);
    /*Граница (задаём толщину и цвет)*/
    box-shadow: 0px 0px 0px 3px #e2e2e28a;
    border-radius: 50%;
    /*Тень (задаём размах и цвет)*/
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
    border: 10px solid rgba(255, 255, 255, 0.173);
    box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.053);
    border-radius: 50%;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
    border: 5px solid rgba(255, 255, 255, 0.198);
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.166);
    border-radius: 50%;
  }
}
</style>
